import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ReactFullpage from '@fullpage/react-fullpage'

import ProgressMenu from '../components/ProgressMenu'
import MainMenu from '../components/MainMenu'
import RestrictionsMessage from '../components/RestrictionsMessage'
import ScrollIndicator from '../components/ScrollIndicator'
import { CPLogo } from '../assets/svgs/CP-logo'

import { cubicBezier, MQ } from '../assets/styles/constants'

const CategoryProducts = products => (
  <StyledCategoryProducts>
    {products.products.map(product => (
      <CategoryProduct
        key={`categoryProduct-${product.node.slug}`}
        to={`/collection/${product.node.parent_element.slug}/${product.node.slug}`}
      >
        <CategoryProductTitle dangerouslySetInnerHTML={{ __html: product.node.title }} />
      </CategoryProduct>
    ))}
  </StyledCategoryProducts>
)

class Collection extends React.Component {
  state = {
    activePage: 1,
    showScrollIndicator: true,
  }

  handleReactFullPageAfterLoad = ({ origin, destination, direction }) => {
    this.setState({
      activePage: destination.index + 1,
      showScrollIndicator: false,
    })
  }

  render() {
    const data = this.props.data.wordpressPage.acf
    const categories = this.props.data.categories
    const itemsForProgressMenu = 1 + categories.edges.length
    const products = this.props.data.products

    return (
      <React.Fragment>
        <MainMenu />
        <RestrictionsMessage />
        <ProgressMenu selected={this.state.activePage} items={itemsForProgressMenu} />
        <ReactFullpage
          lazyLoading={false}
          licenseKey="78B68DD0-5E89429F-83882F6A-5CF1BF97"
          onLeave={(origin, destination, direction) => {
            this.handleReactFullPageAfterLoad({ origin, destination, direction })
          }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <Section className="section">
                  <CollectionSection>
                    <VideoBackground>
                      <video data-keepplaying autoPlay muted loop preload="auto">
                        <source
                          src={data.video_background && data.video_background.localFile.publicURL}
                          type="video/mp4"
                        />
                      </video>
                    </VideoBackground>
                    <Content>
                      <CPLogo className="cplogo" />
                      <Headline>{data.headline}</Headline>
                      <Subline>{data.subline}</Subline>
                    </Content>
                  </CollectionSection>
                </Section>
                {categories.edges.map(category => {
                  if (category.node.acf.background_image === null) {
                    return null
                  }

                  const image =
                    category.node.acf.background_image.localFile !== null
                      ? category.node.acf.background_image.localFile.childImageSharp.fluid
                      : category.node.acf.background_image.source_url

                  const imageIsAvailableLocal =
                    category.node.acf.background_image.localFile !== null

                  const categoryProducts = products.edges.filter(
                    product => product.node.parent_element.id === category.node.id,
                  )

                  return (
                    <Section className="section" key={`collectionCategory-${category.node.slug}`}>
                      <CollectionSection>
                        <ImageBackground>
                          {imageIsAvailableLocal ? (
                            <Img
                              fluid={image}
                              critical
                              style={{
                                userSelect: 'none',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                objetFit: 'cover',
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                              }}
                              alt={category.node.title}
                            />
                          ) : (
                            <img
                              src={image}
                              style={{
                                userSelect: 'none',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                objetFit: 'cover',
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                              }}
                              alt={category.node.title}
                            />
                          )}
                        </ImageBackground>
                        <Content>
                          <Headline dangerouslySetInnerHTML={{ __html: category.node.title }} />
                          {categoryProducts.length > 0 && (
                            <CategoryProducts products={categoryProducts} />
                          )}
                        </Content>
                      </CollectionSection>
                    </Section>
                  )
                })}
              </ReactFullpage.Wrapper>
            )
          }}
        />

        <ScrollIndicator
          label="Explore collections"
          showScrollIndicator={this.state.showScrollIndicator}
        />
      </React.Fragment>
    )
  }
}

export default Collection

export const pageQuery = graphql`
  query {
    wordpressPage(template: { eq: "page-frontpage.php" }) {
      acf {
        headline
        subline
        video_background {
          source_url
          localFile {
            publicURL
          }
        }
      }
    }
    categories: allWordpressPage(
      filter: { template: { eq: "page-category-frontpage.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          menu_order
          acf {
            background_image {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    products: allWordpressPage(
      filter: { template: { eq: "page-category-product.php" } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title
          menu_order
          parent_element {
            id
            slug
          }
        }
      }
    }
  }
`

const CollectionSection = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const VideoBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  > video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`

const Section = styled.div``

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;

  .cplogo {
    transform: scale(0.8);
    @media screen and (${MQ.lg}) {
      transform: scale(1);
    }
  }
`

const Headline = styled.span`
  display: block;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (${MQ.lg}) {
    font-size: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

const Subline = styled.span`
  display: block;
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px;

  @media screen and (${MQ.lg}) {
    font-size: 50px;
  }
`

const ImageBackground = styled.div`
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`

const StyledCategoryProducts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 80px;
  padding-left: 15vw;
  padding-right: 15vw;
`

const CategoryProduct = styled(Link)`
  flex: 1;
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;

  @media screen and (${MQ.lg}) {
    &:hover {
      transform: scale(1.2);
    }
  }
`

const CategoryProductTitle = styled.span`
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;

  @media screen and (${MQ.lg}) {
    font-size: 20px;
  }
`
